import { SnackbarProvider } from 'notistack'
import { JSXElementConstructor } from 'react'

import { AnyObject } from '@/shared/lib'

export const WithSnackbar = (Component: JSXElementConstructor<any>) => (props: AnyObject) => {
  return (
    <SnackbarProvider
      dense
      maxSnack={1}

    >
      <Component {...props} />
    </SnackbarProvider>
  )
}
