import { useQueryClient } from '@tanstack/react-query'

import { PlayerInfo } from '@/swagger/Api'

import { playerKeys } from '../lib'

export const useUpdatePlayerInfo = () => {
  const queryClient = useQueryClient()

  return (updater: ((prevData: PlayerInfo) => PlayerInfo) | PlayerInfo) =>
    queryClient.setQueryData(playerKeys.playerInfo(), updater)
}
