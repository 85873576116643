import { MiniAppsMethodParams } from '@telegram-apps/sdk-react'
import { createContext } from 'react'

export type SettingsName = 'notifications' | 'vibrations'

export interface HapticContextProps {
  triggerHaptic: (setting: SettingsName, params: MiniAppsMethodParams<'web_app_trigger_haptic_feedback'>) => void;
  toggleSettings: (setting: SettingsName) => void;
  settingsState: Record<SettingsName, boolean>;
}

export const defaultSettings = {
  notifications: true,
  vibrations: true,
}

const defaultHapticOptions: HapticContextProps = {
  toggleSettings: () => {},
  triggerHaptic: () => {},
  settingsState: defaultSettings,
}

export const HapticContext = createContext<HapticContextProps>(defaultHapticOptions)
