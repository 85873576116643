import { useUpdatePlayerInfo } from '../../model'

export const useBalanceManagment = () => {
  const updatePlayerInfo = useUpdatePlayerInfo()

  return (value: number) => {
    updatePlayerInfo((prevData) => {
      if (!prevData.stats) return prevData

      const { balance } = prevData.stats

      const newBalance = (balance ? +balance : 0) + value

      return {
        ...prevData,
        stats: {
          ...prevData.stats,
          balance: String(newBalance),
        },
      }
    })
  }
}
