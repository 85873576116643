import { JSXElementConstructor, useMemo } from 'react'

import { AnyObject } from '@/shared/lib'

import { PlayerInfoManagmentContext } from './PlayerInfoManagmentContext'
import { useBalanceManagment } from './useBalanceManagment'
import { useEnergyManagment } from './useEnergyManagment'
import { useStorageAmountManagment } from './useStorageAmountMangmanet'

export const WithPlayerInfoManagment = (Component: JSXElementConstructor<any>) => (props: AnyObject) => {
  const takeUnitEnergy = useEnergyManagment()
  const addBalance = useBalanceManagment()
  useStorageAmountManagment()

  const value = useMemo(() => ({
    takeUnitEnergy,
    addBalance,
  }), [takeUnitEnergy, addBalance])

  return (
    <PlayerInfoManagmentContext.Provider value={value}>
      <Component {...props} />
    </PlayerInfoManagmentContext.Provider>
  )
}
