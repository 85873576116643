import { useCallback } from 'react'
import { useInterval } from 'usehooks-ts'

import { PlayerInfo } from '@/swagger/Api'

import { usePlayerStats } from '../../api'
import { useUpdatePlayerInfo } from '../../model'

const energyUpdateInterval = 1000

export const useEnergyManagment = () => {
  const { data } = usePlayerStats()
  const updatePlayerInfo = useUpdatePlayerInfo()
  const currentEnergy = data?.energy ?? 0
  const maxEnergy = data?.max_energy ?? null

  const incrementEnergy = useCallback(() => {
    updatePlayerInfo((prevData) => {
      if (!prevData.stats) return prevData

      const { energy, energy_update_rate, max_energy } = prevData.stats

      const summedEnergy = energy + energy_update_rate
      const addedValue = summedEnergy >= max_energy ? max_energy : summedEnergy

      return {
        ...prevData,
        stats: {
          ...prevData.stats,
          energy: addedValue,
        },
      }
    })
  }, [updatePlayerInfo])

  useInterval(incrementEnergy, currentEnergy === maxEnergy ? null : energyUpdateInterval)

  return useCallback((amount: number) => {
    updatePlayerInfo((prevData: PlayerInfo) => {
      if (!prevData.stats) return prevData

      const { energy } = prevData.stats

      const value = energy - amount

      return {
        ...prevData,
        stats: {
          ...prevData.stats,
          energy: value > 0 ? value : 0,
        },
      }
    })
  }, [updatePlayerInfo])

}
