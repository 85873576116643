class TaskKeys {
  task = () => ['task']

  list = () => [...this.task(), 'list']

  check = () => [...this.task(), 'check']

  claim = () => [...this.task(), 'claim']
}

export const taskKeys = new TaskKeys()
