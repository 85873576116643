import { createContext } from 'react'

export type SpringType = typeof import('@react-spring/web');
export type GestureType = typeof import('@use-gesture/react');

export interface AnimationLibrariesContextPayload {
  gesture?: GestureType;
  spring?: SpringType;
  librariesLoaded?: boolean;
}

export const AnimationLibrariesContext = createContext<AnimationLibrariesContextPayload>({})
