import { memo } from 'react'
import { Outlet } from 'react-router-dom'

import { Menu } from '@/widgets/Menu'

export const Layout = memo(() => {
  return (
    <div className="layout">
      <Outlet />
      <Menu />
    </div>
  )
})
