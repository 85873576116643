import { createContext } from 'react'

import { appEnvironment } from '@/shared/config'
import { Api } from '@/swagger/Api'

export interface ApiContextProps {
  api: Api<unknown>
}

export const ApiContext = createContext<ApiContextProps>({
  api: new Api<unknown>({ baseUrl: appEnvironment.BASE_URL ?? '' }),
})
