interface AppEnvironment {
  API_URL: string;
  BASE_URL: string;
}

function get<ReturnType extends string> (key: string): ReturnType {
  const env = import.meta.env['VITE_' + key]
  if (env) return env
  throw new Error(`Env var VITE_${key} was not provided`)
}

export const appEnvironment: AppEnvironment = {
  API_URL: get('API_URL'),
  BASE_URL: get('BASE_URL'),
}
