import clsx from 'clsx'
import { FC, memo } from 'react'

import { WithClassName } from '@/shared/lib'

import s from './Counter.module.scss'

interface CounterProps extends WithClassName {
  count: number;
}

export const Counter: FC<CounterProps> = memo(({ count, className }) => {
  const visibleCount = count > 99 ? '99+' : count

  return (
    <span className={clsx(s.counter, className)}>
      {visibleCount}
    </span>
  )

})
