import { CssBaseline, ThemeProvider } from '@mui/material'
import { JSXElementConstructor } from 'react'

import { AnyObject } from '@/shared/lib'

import { theme } from '../../styles/mui'

export const WithTheme = (Component: JSXElementConstructor<any>) => (props: AnyObject) => {
  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
      <CssBaseline />
    </ThemeProvider>
  )
}
