import { useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/lib'
import { User } from '@/swagger/Api'

import { userKeys } from '../lib'

export const useUserInfo = <T>(select?: (data: User) => T) => {
  const { api } = useApi()

  return useQuery({
    queryKey: userKeys.info(),
    queryFn: async () => {
      const { data } = await api.user.infoList({ format: 'json' })

      return data
    },
    select,
  })
}
