/**
 * Safely converts any error to string
 * @param error an error
 */
export function stringifyError(error: unknown): string {

  if (typeof error === 'string') return error

  if (error instanceof Object && 'reason' in error && typeof error.reason === 'string') {
    return error.reason || 'Unknown error with empty reason'
  }

  // errors thrown by metamask connected via wallet connect
  if (
    error instanceof Object &&
      'code' in error &&
      typeof error.code === 'number' &&
      'message' in error &&
      typeof error.message === 'string'
  ) {

    // fallback
    return error.message
  }

  if (error instanceof Error) {
    if (error.stack) return error.stack

    return `${error.name}: ${error.message}`
  }

  let message
  try {
    message = JSON.stringify(error)
    if (message === '{}') {

      message = `${error}`
    }
  } catch (e) {

    message = `${error}`
  }

  return message
}
