import { i18n } from '@lingui/core'
import { I18nProvider as LinguiI18nProvider } from '@lingui/react'
import { useLaunchParams } from '@telegram-apps/sdk-react'
import { FC, PropsWithChildren, useEffect } from 'react'

import { activateLocale } from './activateLocale'
import { toSupportedLocale } from './supportedLocale'

export const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const launchParams = useLaunchParams()
  const locale = launchParams.initData?.user?.languageCode

  useEffect(() => void activateLocale(toSupportedLocale(locale)), [locale])

  return (
    <LinguiI18nProvider i18n={i18n}>
      {children}
    </LinguiI18nProvider>
  )
}
