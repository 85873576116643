import { MiniAppsMethodParams, postEvent, supports, useLaunchParams } from '@telegram-apps/sdk-react'
import { useCallback, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { FCC } from '../../misc'
import { defaultSettings, HapticContext, HapticContextProps, SettingsName } from './HapticContext'

export const HapticProvider: FCC = ({ children }) => {
  const [localStorageSettings, setLocalStorageSettings] = useLocalStorage <Record<SettingsName, boolean>>('settings', defaultSettings)

  const lp = useLaunchParams()

  const toggleSettingsRecord: Record<SettingsName, () => void> = useMemo(() => ({
    'notifications': () => {
      setLocalStorageSettings(prev => ({
        ...prev,
        'notifications': !prev.notifications,
      }))
    },
    'vibrations': () => {
      setLocalStorageSettings(prev => ({
        ...prev,
        'vibrations': !prev.vibrations,
      }))
    },
  }), [setLocalStorageSettings])

  const triggerHaptic = useCallback((setting: SettingsName, params: MiniAppsMethodParams<'web_app_trigger_haptic_feedback'>) => {
    try {
      if (!localStorageSettings[setting]) return

      if (supports('web_app_trigger_haptic_feedback', lp.version)) {
        postEvent('web_app_trigger_haptic_feedback', params)
      }
    } catch (e) {
      // nothing to do, it's not a critical error
      console.error(e)
    }
  }, [lp.version, localStorageSettings])

  const toggleSettings = useCallback((setting: SettingsName) => toggleSettingsRecord[setting](), [toggleSettingsRecord])

  const value: HapticContextProps = useMemo(() => ({
    triggerHaptic,
    settingsState: localStorageSettings,
    toggleSettings,
  }), [triggerHaptic, localStorageSettings, toggleSettings])

  return (
    <HapticContext.Provider value={value}>
      {children}
    </HapticContext.Provider>
  )
}
