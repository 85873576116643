import { useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/lib'
import { PlayerInfo } from '@/swagger/Api'

import { playerKeys } from '../lib/playerKeys'

export const usePlayerInfo = <T>(select: (data: PlayerInfo) => T) => {
  const { api } = useApi()

  return useQuery({
    queryKey: playerKeys.playerInfo(),
    queryFn: async () => {
      const { data } = await api.player.infoList({ format: 'json' })

      return data
    },
    select,
  })
}
