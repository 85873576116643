import { useDataQuestCount } from '@/entities/task'

import { useUserInfo } from './useUserInfo'

export const useTaskCount = (all: boolean = true) => {
  const { data: dataQuestsCount } = useDataQuestCount()
  const { data: allTaskCount } = useUserInfo(data => data?.available_tasks_count ?? 0)

  return all
    ? allTaskCount
    : (allTaskCount ?? 0) - (dataQuestsCount ?? 0)
}
