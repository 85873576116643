import { ErrorResponse } from '@/swagger/Api'

/**
 * Handles error response from the backend
 * @param error from backend
 */
export function errorResponseToMessage(error?: ErrorResponse): string {
  if (!error) {
    return 'received nullish error from the backend, but request was not successful'
  }

  if (error.detail) {
    return `${error.message}${error.detail ? `: ${error.detail}` : ''}`
  }

  if (error.message) {
    return error.message
  }

  return 'Unknown error. Please, reload the page'
}
