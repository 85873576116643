import { useLaunchParams } from '@telegram-apps/sdk-react'
import { useMemo } from 'react'

import { appEnvironment } from '@/shared/config'
import { createCustomFetch, FCC } from '@/shared/lib'
import { Api } from '@/swagger/Api'

import { ApiContext } from './model/types/apiContext'

export const ApiProvider: FCC = ({ children }) => {
  const lp = useLaunchParams()

  const api = useMemo(() => new Api({
    baseUrl: appEnvironment.BASE_URL ?? '',
    customFetch: createCustomFetch(lp.initDataRaw),
  }), [lp.initDataRaw])

  return (
    <ApiContext.Provider value={{ api }}>
      {lp.initDataRaw ? children : 'error placeholder'}
    </ApiContext.Provider>
  )
}
