import { JSXElementConstructor } from 'react'

import { AnyObject } from '@/shared/lib'

import { HapticProvider } from './haptic/HapticProvider'

export const WithSettings = (Component: JSXElementConstructor<any>) => (props: AnyObject) => {
  return (
    <HapticProvider>
      <Component {...props} />
    </HapticProvider>
  )
}
