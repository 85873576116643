import { useCallback, useEffect } from 'react'

export const usePreventLoupe = () => {

  const preventDefault = useCallback((e: Event) => e.preventDefault(), [])

  const legacyPreventDefault = useCallback((e: TouchEvent) => {
    if ('scale' in e && e.scale !== 1) { e.preventDefault() }
  }, [])

  useEffect(() => {
    document.addEventListener('gesturestart', preventDefault)

    document.addEventListener('touchmove', legacyPreventDefault, { passive: false })

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('touchmove', legacyPreventDefault)
    }
  }, [])

}
