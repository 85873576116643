import type { FC } from 'react'

import { DisplayError } from '@/shared/ui'

export const ErrorBoundarySDK: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>
      An error occurred while initializing the SDK. You are probably running the application
      outside of Telegram (in usual browser, for example).
    </p>
    <DisplayError error={error} />
  </div>
)
