import { closeSnackbar, useSnackbar as useNotistackSnackbar } from 'notistack'
import { useMemo } from 'react'

export const useSnackbar = () => {
  const { enqueueSnackbar } = useNotistackSnackbar()

  return useMemo(() => ({
    error: (message: string) => enqueueSnackbar(message, {
      autoHideDuration: 2500,
      variant: 'error',
      preventDuplicate: true,
      SnackbarProps: {
        onMouseEnter: () => closeSnackbar(),
        onMouseMove: () => closeSnackbar(),
      },
    }),
    success: (message: string) => enqueueSnackbar(message, {
      autoHideDuration: 2000,
      variant: 'success',
      preventDuplicate: true,
      SnackbarProps: {
        onMouseEnter: () => closeSnackbar(),
        onMouseMove: () => closeSnackbar(),
      },
    }),
  }), [])
}
