import { TypographyOptions } from '@mui/material/styles/createTypography'

export const getTypography = (): TypographyOptions => ({
  htmlFontSize: 14,
  fontFamily: 'Sora',
  fontWeightBold: 600,
  fontWeightLight: 400,
  fontWeightMedium: 400,
  fontWeightRegular: 400,
})
