import clsx from 'clsx'
import { FC, memo } from 'react'

import { useMenuItems } from '../lib/useMenuItems'
import s from './Menu.module.scss'

export const Menu: FC = memo(() => {
  const menuItems = useMenuItems()

  return (
    <div className={clsx(s.container)}>
      {menuItems}
    </div>
  )
})
