import { ComponentType, useEffect } from 'react'

import { FCC } from '@/shared/lib'

import { useLogin } from '../api/useLogin'

interface LoginGateProps {
  // error, loading and initial should also accept ReactNode, but I am too lazy for that
  error: ComponentType<{
    error: unknown;
  }>
  loading: ComponentType
  initial: ComponentType
}

export const LoginGate: FCC<LoginGateProps> = ({
  children,
  error: Error,
  initial: Initial,
  loading: Loading,
}) => {
  const login = useLogin()

  useEffect(() => {
    login.mutate()
  }, [])

  if (login.isPending) {
    return (
      <Loading />
    )
  }
  if (login.isError) {
    return (
      <Error error={login.error} />
    )
  }

  if (login.isSuccess) {
    return children
  }

  return (
    <Initial />
  )
}
