import { AnyObject } from '@/shared/lib'

import { ErrorBoundary, ErrorBoundaryError } from './errorBoundary'

export const WithErrorBoundary = (Component: any) => (props: AnyObject) => {

  return (
    <ErrorBoundary fallback={ErrorBoundaryError}>
      <Component {...props} />
    </ErrorBoundary>
  )
}
