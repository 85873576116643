import { useInterval } from 'usehooks-ts'

import { usePlayerStats } from '../../api'
import { useUpdatePlayerInfo } from '../../model'

export const useStorageAmountManagment = () => {
  const updatePlayerInfo = useUpdatePlayerInfo()
  const { data } = usePlayerStats()
  const amount = parseFloat(data?.storage_amount ?? '0')
  const capacity = parseFloat(data?.storage_capacity ?? '0')
  const hasFreeCapacity = amount < capacity

  const incrementAmount = () => {
    updatePlayerInfo((prevData) => {
      if (!prevData.stats) return prevData

      const { storage_amount, storage_capacity, passive_rate, time_to_fill } = prevData.stats

      if (!storage_amount || !storage_capacity || !passive_rate) return prevData

      const newBalance = parseFloat(storage_amount) + parseFloat(passive_rate)

      return {
        ...prevData,
        stats: {
          ...prevData.stats,
          storage_amount: String(newBalance),
          time_to_fill: time_to_fill === 0 ? time_to_fill : time_to_fill - 1,
        },
      }
    })
  }

  useInterval(incrementAmount, hasFreeCapacity ? 1000 : null)
}
