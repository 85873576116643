import { i18n } from '@lingui/core'
import { Settings } from 'luxon'

export const activateLocale = async (locale: string) => {
  const { messages } = await import(`./locales/${locale}.po`)

  i18n.load(locale, messages)
  i18n.activate(locale)

  Settings.defaultLocale = locale
}
