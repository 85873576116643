import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { JSXElementConstructor, useMemo } from 'react'

import { onMutationError, onQueryError } from '@/shared/api'
import { AnyObject, useSnackbar } from '@/shared/lib'

export const WithQueryClient = (Component: JSXElementConstructor<any>) => (props: AnyObject) => {
  const snackbar = useSnackbar()

  const queryClient = useMemo(() => new QueryClient({
    queryCache: new QueryCache({
      onError: onQueryError(snackbar.error),
    }),
    mutationCache: new MutationCache({
      onError: onMutationError(snackbar.error),
    }),
  }), [])

  return (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  )
}
