import { postEvent, useBackButton } from '@telegram-apps/sdk-react'
import { useCallback, useEffect } from 'react'

import { useSupports } from '@/shared/lib'

export const useBackButtonCloseApp = () => {
  const backButton = useBackButton()
  const supports = useSupports()

  const closeApp = useCallback(() => {
    if (supports('web_app_close')) {
      postEvent('web_app_close')
    }
  }, [supports])

  useEffect(() => {
    backButton.on('click', closeApp)

    return () => backButton.off('click', closeApp)
  }, [backButton])
}
