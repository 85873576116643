export type SupportedLocale = 'ru' | 'en'

export const defaultLocale: SupportedLocale = 'en'
export const supportedLocales: SupportedLocale[] = ['en', 'ru']

const isSupportedLocale = (locale: string | undefined): locale is SupportedLocale => {
  return supportedLocales.includes(locale as SupportedLocale)
}

export const toSupportedLocale = (locale: string | undefined): SupportedLocale => {
  const _locale = locale?.toLowerCase()

  if (isSupportedLocale(_locale)) {
    return _locale
  }

  return defaultLocale
}
