import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { matchPath, NavLink } from 'react-router-dom'

import type { MenuItem as MenuItemType } from '../../model/menuItems/menuItems'
import s from './MenuItem.module.scss'

type MenuItemProps = MenuItemType & {
  pathname: string;
  additional?: ReactNode;
}

export const MenuItem: FC<MenuItemProps> = ({ imgPath, name, path, additional, disabled, pathname }) => {

  return (
    <div className={s.wrapper}>
      <NavLink
        to={path}
        className={clsx(
          s.item,
          matchPath(path, pathname) && s.active,
          disabled && s.disabled,
        )}
      >
        <img src={imgPath} alt={name} />
        <span className={s.name}>
          {t`${name}`}
        </span>
      </NavLink>
      {additional}
    </div>
  )
}
