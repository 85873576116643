import { forwardRef } from 'react'

import { Flex } from '../Flex/Flex'
import { FlexProps } from '../flexTypes'

type VStackProps = Omit<FlexProps, 'direction'>;

export const VStack = forwardRef<HTMLDivElement, VStackProps>((props, ref) => {
  return (
    <Flex {...props} ref={ref} direction="column" />
  )
})
