import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import { FriendsPage } from '@/pages/Friends'
import { appRoutes } from '@/shared/config'
import { to } from '@/shared/lib'
import { MainPage } from '@/pages/Main'
import { UpgradesPage } from '@/pages/Upgrades'
import { QuestsPage } from '@/pages/Quests'
import { Layout } from '@/app/ui'
import { Suspense } from "react";
import { AppLoader } from "@/shared/ui";


export const appRouteConfig: RouteObject[] = [
  {
    path: appRoutes.root,
    element: <Layout />,
    children: [
      {
        path: appRoutes.root,
        element: (
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            path: appRoutes.main,
            element: <Navigate to={to.game()} />
          },
          {
            path: appRoutes.game,
            element: <MainPage />,
          },
          {
            path: appRoutes.friends,
            element: <FriendsPage />
          },
          {
            path: appRoutes.upgrades,
            element: <UpgradesPage />
          },
          {
            path: appRoutes.quests,
            element: <QuestsPage />
          },
          {
            path: appRoutes.notFound,
            element: <Navigate to={to.game()} />
          },
        ]
      }
    ]
  }
]
