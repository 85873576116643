import { DateTime, Duration, Settings } from 'luxon'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'

export interface CountdownRenderProps {
  duration: Duration, // how much left
  formatted: string, // how much left, but formatted
  finished: boolean, // indicates, if any time left
}

export interface CountdownProps {
  date?: number // the time when countdown finishes
  interval?: number
  render: (props: CountdownRenderProps) => ReactNode
}

console.log(Settings.defaultLocale)

const hours = Settings.defaultLocale === 'ru' ? 'ч' : 'h'
const minutes = Settings.defaultLocale === 'ru' ? 'м' : 'm'

const durationFormat = `h${hours} m${minutes}`

export const Countdown: FC<CountdownProps> = ({
  date,
  interval = 1000,
  render,
}) => {
  if (!date) return '0h 0m'
  const [duration, setDuration] = useState(DateTime.now().plus({ millisecond: date }).diffNow())

  const diff = DateTime.now().plus({ millisecond: date }).diffNow()

  if (diff.toMillis() <= 0) return
  const updateDuration = useCallback(() => {

    setDuration(diff)
  }, [date])
  const finished = duration.toMillis() <= 0

  useInterval(updateDuration, finished ? null : interval)

  // used to start count again when new date is provided
  useEffect(updateDuration, [date])

  return render({ duration, formatted: duration.normalize().toFormat(durationFormat), finished })
}
