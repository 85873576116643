import { useViewport } from '@telegram-apps/sdk-react'
import { useLayoutEffect, useState } from 'react'

export const useMaxSizes = () => {
  const viewport = useViewport()
  const width = viewport?.width || 1
  const height = viewport?.stableHeight || 1

  const [sizes, setSizes] = useState({ height, width })

  useLayoutEffect(() => {
    if (sizes.width === width && sizes.height < height) {
      setSizes(sizes => ({ ...sizes, height }))
    }

    if (sizes.width != width) {
      setSizes({ width, height })
    }
  }, [width, height])

  return sizes
}
