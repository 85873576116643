import ArrowRight from './arrow-right.svg?react'
import Check from './check.svg?react'
import Close from './close.svg?react'
import Energy from './energy.svg?react'
import FileCopy from './file-copy.svg?react'
import FileD from './file-d.svg?react'
import Info from './info.svg?react'
import Logo from './logo.svg?react'
import Settings from './settings.svg?react'
import USDT from './usdt.svg?react'

export const icons = {
  logo: { Component: Logo },
  close: { Component: Close },
  fileD: { Component: FileD },
  info: { Component: Info },
  fileCopy: { Component: FileCopy },
  settings: { Component: Settings },
  energy: { Component: Energy },
  arrowRight: { Component:ArrowRight },
  check: { Component: Check },
  usdt: { Component: USDT },
} as const

export type IconName = keyof typeof icons;
