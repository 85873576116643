import { useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/lib'
import { TaskListResponse } from '@/swagger/Api'

import { taskKeys } from '../lib'

export const useTaskListQuery = <T>(select: (data: TaskListResponse) => T) => {
  const { api } = useApi()

  return useQuery({
    queryKey: taskKeys.list(),
    queryFn: async () => {
      const { data } = await api.task.listList({ format: 'json' })

      return data
    },
    select,
  })
}
