// Всегда объявляем пути как методы, для консистентности

import { appRoutes } from '@/shared/config'

class To {
  main = () => `${appRoutes.main}`

  game = () => `/${appRoutes.game}`

  friends = () => `/${appRoutes.friends}`

  quests = () => `/${appRoutes.quests}`

  upgrades = () => `/${appRoutes.upgrades}`

  notFound = () => appRoutes.notFound
}

export const to = new To()
