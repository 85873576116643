import compose from 'compose-function'

import { WithPlayerInfoManagment } from '@/entities/player'
import { WithSettings } from '@/shared/lib'

import { WithErrorBoundary } from './error'
import { WithQueryClient } from './queryClient'
import { WithSnackbar } from './snackbar'
import { WithTheme } from './theme'

export const withProviders = compose(WithErrorBoundary, WithSnackbar, WithQueryClient, WithTheme)
export const withComplementaryProviders = compose(WithSettings, WithPlayerInfoManagment)
