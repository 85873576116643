import { MutationCache, QueryCache } from '@tanstack/react-query'

import { ErrorResponse, HttpResponse } from '@/swagger/Api'

import { errorResponseToMessage, stringifyError } from '../lib'

const showResponseError = (alertError: (error: string) => void, response: HttpResponse<unknown, ErrorResponse>) => {
  if (response.error) {
    console.error('show response error', response.error)
    // for backend errors
    alertError(errorResponseToMessage(response.error))
  } else {
    // for nginx errors
    const error = response instanceof TypeError ? response : response.statusText
    alertError(stringifyError(error))
  }
}

export const onQueryError: (alertError: (error: string) => void) => QueryCache['config']['onError'] = (alertError) => {
  return async (response) => {
    showResponseError(alertError, response)
  }
}

export const onMutationError: (alertError: (error: string) => void) => MutationCache['config']['onError'] = (alertError) => {
  return async (response) => {
    console.error('onMutationError', response)
    showResponseError(alertError, response)
  }
}
