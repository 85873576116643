import type { FC } from 'react'

import { DisplayError } from '@/shared/ui'

export const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>
      An unhandled error occurred:
    </p>
    <DisplayError error={error} />
  </div>
)
