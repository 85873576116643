import type { FC } from 'react'

export const DisplayError: FC<{ error: unknown }> = ({ error }) => (
  <blockquote>
    <code>
      {error instanceof Error
        ? error.message
        : JSON.stringify(error)}
    </code>
  </blockquote>
)
