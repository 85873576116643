import { createContext } from 'react'

interface PlayerInfoManagmentContextProps {
  takeUnitEnergy: (amount: number) => void;
  addBalance: (value: number) => void;
}

const defaultProps: PlayerInfoManagmentContextProps = {
  addBalance: () => {},
  takeUnitEnergy: () => {},
}

export const PlayerInfoManagmentContext = createContext<PlayerInfoManagmentContextProps>(defaultProps)
